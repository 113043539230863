import * as styles from 'components/new-components/Skeleton/Skeleton.css';
import { vars } from 'styles/variables.css';
import { CardOptions } from 'matrix';
import { baseStyle } from './CarouselWrapper.css';

const createComponentArray = (numberOfComponents: number) => Array.from({ length: numberOfComponents }, (_, index) => index + 1);

type IEventSkeletonProps = {
  untitled: boolean;
  numberOfComponents: number;
  fadeIn?: boolean;
};

function SkeletonItem({
  width = '25%',
  minWidth = '200px',
  maxWidth = '260px',
  height = '140px',
  marginBottom = vars.space[16],
  radius = vars.border.radius[4],
}) {
  return (
    <div
      className={styles.skeletonComponent}
      style={{
        marginBottom, minWidth, maxWidth, height, borderRadius: radius,
      }}
    />
  );
}

export function EventSkeleton({
  untitled,
  numberOfComponents,
  fadeIn = false,
}: IEventSkeletonProps) {
  return (
    <div
      className={`${styles.skeletonContainer} ${baseStyle} ${fadeIn ? 'animated fadeIn' : ''}`}
      style={{ backgroundColor: 'transparent', alignItems: 'flex-start', overflow: 'hidden' }}
    >
      {!untitled && <SkeletonItem width="25%" minWidth="200px" />}

      <div
        className={styles.skeletonContainer}
        style={{
          backgroundColor: 'transparent', padding: 0, flexDirection: 'row', justifyContent: 'flex-start', gap: vars.space[48],
        }}
      >
        {createComponentArray(numberOfComponents).map((n) => (
          <div
            key={`event-skeleton-${n}`}
            className={styles.skeletonContainer}
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <SkeletonItem />
            <SkeletonItem width="25%" minWidth="180px" height="14px" />
            <SkeletonItem width="95%" height="10px" />
            <SkeletonItem width="25%" minWidth="180px" height="10px" />
          </div>
        ))}
      </div>
    </div>
  );
}

export function EventMobileSkeleton({
  untitled,
  numberOfComponents,
  fadeIn = false,
}: IEventSkeletonProps) {
  return (
    <div
      className={`${styles.skeletonContainer} ${baseStyle} ${fadeIn ? 'animated fadeIn' : ''}`}
      style={{
        backgroundColor: 'transparent',
      }}
    >
      {!untitled && <SkeletonItem width="25%" minWidth="200px" />}

      <div
        className={styles.skeletonContainer}
        style={{
          padding: 0, backgroundColor: 'transparent', flexDirection: 'column', gap: vars.space[32],
        }}
      >
        {createComponentArray(numberOfComponents).map((n) => (
          <div
            key={`event-skeleton-mobile-${n}`}
            className={styles.skeletonContainer}
            style={{
              padding: 0, backgroundColor: 'transparent', flexDirection: 'row', gap: vars.space[16],
            }}
          >
            <SkeletonItem
              maxWidth="104px"
              minWidth="104px"
              width="104px"
              height="80px"
              marginBottom={vars.space[0]}
              radius={vars.border.radius[12]}
            />
            <div
              className={styles.skeletonContainer}
              style={{
                padding: 0, backgroundColor: 'transparent', alignItems: 'flex-start', justifyContent: 'center', gap: vars.space[8],
              }}
            >
              <SkeletonItem width="25%" maxWidth="180px" height="14px" marginBottom={vars.space[0]} />
              <SkeletonItem width="90%" maxWidth="100px" height="10px" marginBottom={vars.space[0]} />
              <SkeletonItem width="25%" maxWidth="100px" height="10px" marginBottom={vars.space[0]} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function OrganizerSkeleton({ untitled, numberOfComponents }: { untitled: boolean; numberOfComponents: number }) {
  return (
    <div className={styles.skeletonContainer} style={{ alignItems: 'flex-start' }}>
      {!untitled && <SkeletonItem width="25%" minWidth="200px" />}
      <div className={styles.skeletonContainer} style={{ flexDirection: 'row', gap: vars.space[40] }}>
        {createComponentArray(numberOfComponents).map((n) => (
          <SkeletonItem key={`organizer-skeleton-${n}`} width="150px" height="200px" />
        ))}
      </div>
    </div>
  );
}

export function CarouselWrapperSkeleton({
  untitled = false,
  type,
  numberOfComponents,
  fadeIn = false,
  isMobile = false,
}: {
  untitled?: boolean;
  type: keyof CardOptions;
  numberOfComponents: number;
  fadeIn?: boolean;
  isMobile?: boolean;
}) {
  if (type === 'organizer-card') {
    return <OrganizerSkeleton untitled={untitled} numberOfComponents={numberOfComponents} />;
  }

  if (isMobile) {
    return <EventMobileSkeleton untitled={untitled} numberOfComponents={numberOfComponents} fadeIn={fadeIn} />;
  }

  return <EventSkeleton untitled={untitled} numberOfComponents={numberOfComponents} fadeIn={fadeIn} />;
}

export function HightLightWrapperSkeleton() {
  return (
    <div>
      <div className={styles.hightLightSkeleton}>
        <SkeletonItem minWidth="15%" height="440px" />
        <SkeletonItem minWidth="70%" height="480px" />
        <SkeletonItem minWidth="15%" height="440px" />
      </div>
      <div className={styles.hightLightSkeletonContainer}>
        <SkeletonItem minWidth="100px" height="10px" />
        <SkeletonItem minWidth="700px" height="30px" />
        <SkeletonItem minWidth="300px" height="20px" />
      </div>
    </div>
  );
}
